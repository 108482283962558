import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const Contact = () => {
  const [courseContact, setCourseContact] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        // Bước 1: Gọi API đầu tiên để lấy danh sách contact
        const res = await fetch(`https://api.chaulanart.com/contact/`);
        const contacts = await res.json();

        // Bước 2: Dựa vào course_item_id từ từng contact để gọi API thứ hai
        const contactsWithCourseNames = await Promise.all(
          contacts.map(async (contact) => {
            try {
              const courseRes = await fetch(
                `https://api.chaulanart.com/course/course-id/${contact.course_item_id}`
              );
              const courseData = await courseRes.json();

              // Gộp dữ liệu từ API thứ hai vào từng contact
              return { ...contact, name_course: courseData.name };
            } catch (error) {
              console.error(
                `Error fetching course data for contact ${contact.contact_id}:`,
                error
              );
              return { ...contact, name: null }; // Gán name là null nếu có lỗi
            }
          })
        );

        setCourseContact(contactsWithCourseNames);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchProjects();
  }, []);

  console.log(courseContact);

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody>
        {itemInPage.slice(0, 10).map((project) => (
          <tr>
            <td>{project.name}</td>
            <td>{project.phone}</td>
            <td>{project.email}</td>
            <td>{project.description}</td>
            <td>
              <p
                dangerouslySetInnerHTML={{
                  __html: project.name_course,
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(courseContact.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={courseContact.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      <h2>Danh sách đăng ký tư vấn</h2>
      <table>
        <thead>
          <tr>
            <th>Họ tên</th>
            <th>Số điện thoại</th>
            <th>Email</th>
            <th>Nội dung</th>
            <th>Khóa học</th>
          </tr>
        </thead>
        <PageItem pageSize={10} />
      </table>
    </div>
  );
};
export default Contact;
